<!--Unused view -->
<template>
  <v-container class="archive pa-0 ma-0" fluid>
    <v-card flat class="pb-0 mb-0 pt-0">
      <v-card-text class="pb-2 mb-0 px-0 mt-0 pt-2">
        <div>
          <v-toolbar flat>
            <v-toolbar-title class="ml-2 title">
              {{ currentResource ? currentResource.title : '' }}
            </v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
          </v-toolbar>
        </div>
        <v-divider></v-divider>
        <MetabaseEmbed
          v-show="currentPath"
          :path="currentPath"
          :fitHeight="fitHeight">
        </MetabaseEmbed>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import MetabaseEmbed from '@/components/MetabaseEmbed.vue'
import MetabaseApi from '@/axios/metabase'
import { metabaseMixin } from '@/mixins/metabase'
export default {
  data () {
    return {
      currentPath: null,
      currentResource: null
    }
  },
  name: 'MetabaseView',
  mixins: [metabaseMixin],
  components: { MetabaseEmbed },
  props: ['id'],
  watch: {
    id: {
      handler (newValue) {
        if (newValue && typeof newValue === 'number') {
          this.setCurrentResource(newValue)
          this.getEmbedUrl()
        }
      }
    }
  },
  async created () {
    if (this.id) {
      this.setCurrentResource(this.id)
      await this.getEmbedUrl()
    }
  },
  methods: {
    setCurrentResource (id) {
      const item = this.resources.find(r => r.id === Number(id))
      const { title, resource } = item
      return this.currentResource = {
        title,
        resource,
        id: Number(id)
      }
    },
    async getEmbedUrl () {
      if (this.currentResource) {
        const { resource, id } = this.currentResource
        const embedUrl = await MetabaseApi.getSignedEmbedUrl(resource, id)
        this.currentPath = embedUrl
      }
    }
  }
}
</script>
